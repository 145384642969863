<template>
  <div class="mt-4 pr-0 pl-0 pt-2 border_all" style="border: thin solid rgba(0,0,0,.12);border-radius: 5px" elevation="0">
    <v-toolbar elevation="0">
      <v-text-field
        rounded
        dense
        filled
        prepend-icon="mdi-text-search"
        placeholder="Type something"
        v-model="opsitable.search"
        hide-details
        clearable
        clear-icon="mdi-close-circle"
        class="hidden-sm-and-down"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="openaddnew">
        <v-icon dark>mdi-plus</v-icon>Tambah
      </v-btn>
      <v-btn small elevation="0" class="ml-2" color="primary" @click.native="getScreenLayout(opsitable)">
        <v-icon dark>mdi-refresh</v-icon>Refresh
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      disable-sort
      :headers="headers"
      :search="search"
      :items="screenlayout"
      class="elevation-0"
      item-key="id"
      :options.sync="opsitable"
      :server-items-length="totalscreenlayout"
      :loading="loading"
      @update:options="getScreenLayout(opsitable)">
      <template v-slot:item.action="{ item }">
        <v-row class="mr-1">
          <v-btn x-small fab text @click.native="openedit(item)" class="primary">
            <v-icon small>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn x-small dark fab text @click.native="confmDelete(item)" class="red">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ScreenLayoutPage",
  data: ()=>({
    search: "",
    opsitable: {
      search:'',
      itemsPerPage: 10,
      page:1,
      sortBy: ["id"],
    },
    headers: [
      {
        text: 'id',
        value: 'id'
      },
      {
        text: 'GroupOperator',
        value: 'namaoperator'
      },
      {
        text: 'ProdukLayanan',
        value: 'namaproduk'
      },
      {
        text: 'IdProduk',
        value: 'idproduk'
      },
      {
        text: 'JenisTrx',
        value: 'jenistrx'
      },
      {
        text: 'Pattern',
        value: 'pattern'
      },
      {
        text: 'Aksi',
        value: 'action',
        width: '90px'
      },
    ],
  }),
  mounted () {
  },
  computed: {
    ...mapState('ScreenLayoutStore',['loading','dialog','totalscreenlayout','screenlayout'])
  },
  methods: {
    ...mapActions('ScreenLayoutStore',['setdialog','setloading','getScreenLayout','getScreenLayoutByid','resetselecteditem','deleteData','setselecteditem']),
    openaddnew(){
      this.resetselecteditem();
      this.$router.push('/screenlayout/new');
    },
    openedit(item){
      this.setselecteditem(item)
      this.$router.push('/screenlayout/edit/'+item.id);
    },
    confmDelete (item) {
      this.$confirm('Yakin akan hapus ?' +item.namaproduk, { color: 'red',title:'Hapus',icon:'fa-danger' }).then(res => {
        if (res) {
          this.deleteData(item)
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
