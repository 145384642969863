import { handleResponse } from '@/services/handleresponse'
import { apiurl } from '@/services/config'
const API_URL = apiurl


export const screenLayoutService = {
  getScreenLayout,
  getScreenLayoutByid,
  addScreenLayout,
  updateScreenLayout,
  deleteScreenLayout
}
function getScreenLayoutByid (id) {
  return fetch(API_URL + '/api/admin/v1/screenlayout/'+id, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function getScreenLayout (opt) {
  var sort="id"
  var sorder="DESC"
  if(opt.sortBy){
    // console.log(opt.sortBy)
  }
  if(opt.sortDesc){
    if(opt.sortDesc[0]){
      sorder="DESC"
    }
  }
  if(opt.itemsPerPage<0){
    opt.itemsPerPage=10000
  }
  let search=opt.search
  if(search==null){
    search=""
  }

  let page=opt.page-1;
  let sorting=sort+","+sorder
  return fetch(API_URL + '/api/admin/v1/screenlayout?search='+search+'&page='+page+'&size='+opt.itemsPerPage+"&sort="+sorting, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function addScreenLayout (postdata) {
  return fetch(API_URL + '/api/admin/v1/screenlayout', {
    method: 'POST',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}

function updateScreenLayout (postdata) {
  return fetch(API_URL + '/api/admin/v1/screenlayout', {
    method: 'PATCH',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
function deleteScreenLayout (postdata) {
  return fetch(API_URL + '/api/admin/v1/screenlayout', {
    method: 'DELETE',
    body: JSON.stringify({ postdata }.postdata),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }).then(handleResponse)
    .then(data => {
      return data
    })
}
